:root {
    --primary-color: #E21454;
    --secondary-color: #1F43B2;
    --tertiary-color: #cddc79;
    --tertiary-color-hover: #d4df98;
    --quartiary-color: #697424;
    --text-color: #3b3b3b;
    --text-color-hover: #fdece7;
    --bg-primary-color: #f9baa9;
    --bg-secondary-color: #fdece7;
}

.carousel-card-button {
    background-color: var(--tertiary-color);
    border-color: var(--tertiary-color);
    color: var(--text-color);
}

.carousel-card-button:hover, .carousel-card-button:focus, .carousel-card-button:active {
    background-color: var(--tertiary-color-hover);
    border-color: var(--tertiary-color-hover);
    color: var(--text-color-hover);
}

.bg-card-primary {
    background-color: var(--bg-primary-color);
    color: var(--text-color);
}

.bg-card-secondary {
    background-color: var(--bg-secondary-color);
}

.bg-blog {
    background-color: white;
}

.post-title-h1-primary,
.divider-solid {
    color: var(--text-color);
}

.post-title-h2-primary {
    color: var(--quartiary-color);
}

/* .post-p,
.post-p-small {
    color: var(--text-color);
} */

.post-title-h1-primary {
    font-weight: 700;
    font-size: 2.813rem;
}

.post-p {
    font-size: 1.563rem;
}

.post-p-small {
    color: var(--bs-body-color);
    text-decoration: none;
    font-size: 1rem;
}

.divider-solid {
    border-top: 2px solid;
}

@media(width <=767.98px) {

    #postNav,
    #postInfoHead {
        .post-p-small {
            font-size: 0.75rem;
        }
    }

    #post {
        .post-title-h1-primary {
            font-size: 1.6rem;
        }

        .post-p {
            font-size: 0.9rem;
        }
    }
}