.text-primary {
  color: #F15025,
}

.text-white {

    color: #FFF,
}

.bg-primary {
 background-color: #F47553,
}


.my-custom-button {
  border: none !important; /* Sin bordes */
  padding: 0.5rem; /* Espaciado interno */
  border-radius: 5px; /* Bordes redondeados */
  /* Añade cualquier otra propiedad que necesites */
}


.nav-link-normal {
  color: #F15025; /* Define el color del texto */
  text-decoration: none; /* Elimina el subrayado */
  font-weight: normal; /* Asegura que el texto no esté en negrita */
}

/* Navi.css */
.navbar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-button {
  background-color: #F47553 !important;
  border-color: #F47553 !important;
  color: white;
}

.color-review {

  color: #3F4446;
}

.texto-review {
  text-align: left;
  font-size: 1rem;
}

/* Styles.css */
.img-izquierda {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  overflow: hidden; /* Evita que la imagen desborde el contenedor */
  max-width: 100%; /* Asegura que la imagen no sea más ancha que el contenedor */
  height: auto; /* Mantiene la proporción de la imagen */
  margin-left: 5rem;
}


  @media (max-width: 768px) {
    .img-izquierda{
    flex-direction: column;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    overflow: hidden; /* Evita que la imagen desborde el contenedor */
    max-width: 50% !important; /* Asegura que la imagen no sea más ancha que el contenedor */
    height: auto; /* Mantiene la proporción de la imagen */
    }
  }
  
  .texto-derecha {
    /* Estilos para desktop */
    display: block; /* Este será sobrescrito por flex en la versión responsiva */
    text-decoration: none !important;
    float: right;
    text-align: center;
    justify-content: center;
    margin-right: 15rem;
    gap: unset;
    font-size: 1.5rem;
  }
  
  @media screen and (max-width: 768px) {
    .texto-derecha {
      display: flex; /* Habilita flexbox para centrar los elementos */
      flex-direction: column;
      align-items: center; /* Centra horizontalmente */
      justify-content: center; /* Centra verticalmente si hay altura definida */
      list-style: none;
      padding: 0;
      margin-top: 1rem;
      font-size: 1.2rem;
      float: none; /* Anula el float para dispositivos más pequeños */
      margin-right: 0; /* Anula el margen derecho para dispositivos más pequeños */
      width: 100%; /* Toma todo el ancho del contenedor */
      text-align: center; /* Asegura que el texto dentro de los li se centre */
    }


}
/* Clase para limpiar floats */
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.logo-alienado{
margin-left: 5rem !important;
}

.nolink {
  color: black;
text-decoration: none !important;
}

/* Apply Inter font to h1 through h4 */
h1, h2, h3, h4 {
  font-family: 'Inter', sans-serif !important;
}

/* Apply IBM Plex Sans font to label, p, h5, and h6 */
label, p, h5, h6 {
  font-family: 'IBM Plex Sans', sans-serif !important;
}

.step-indicator {
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif; /* or your preferred font */
}

.step-bubble {
  padding: 5px 10px;
  border-radius: 20px; /* circular edges */
  background-color: #ccc;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease; /* smooth transition for background color */
}

.step-bubble.active {
  background-color: #000; /* Active step has blue bg or any color you want */
}

.step-arrow {
  margin: 0 8px;
  color: #333;
  font-weight: normal;
}

/* If the step is completed, you may want to add different styling, e.g., */
.step-bubble.completed {
  background-color: #000; /* Green bg for completed steps */
}

.banner-garlas-llc-heading {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #3F4446;
  font-size: 40px;
  line-height: 46px;
}

.banner-garlas-llc-card-paragraph {
  margin: 0;
  color: black;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
}

.banner-garlas-llc-card-paragraph-2 {
  text-align: end;
  color: black;
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
}


/* phones */
@media (max-width: 992px) {
  .banner-garlas-llc-heading {
    font-size: 24px;
    color: #3F4446;
    line-height: 30px;
  }

  .banner-garlas-llc-card-paragraph {
    font-size: 14px;
    color: #3F4446;
    font-weight: 300;
    line-height: 18px;
  }

  .banner-garlas-llc-card-paragraph-2 {
    font-size: 24px;
    font-weight: 500;
    color: #3F4446;
    line-height: 28px;
  }
}

/* ultra-wide  */
@media (min-width: 1500px) {
  .banner-garlas-llc-heading {
    font-size: 48px;
    line-height: 54px;
  }

  .banner-garlas-llc-card-paragraph {
    font-size: 28px;
    line-height: 32px;
  }

  .banner-garlas-llc-card-paragraph-2 {
    font-size: 30px;
    line-height: 34px;
  }
}

.logo-img {
  object-fit: contain;
  max-height: 100%;
  width: auto;
}

@keyframes slide-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.logo-slider {
  mask-image: linear-gradient(to right, transparent 0, white 10px, white calc(100% - 10px), transparent 100%);
}

.logo-track {
  animation: slide-left 100s linear infinite;
  white-space: nowrap;
}

.logo-slider:hover .logo-track {
  animation-play-state: paused;
}

.logo-slider-img {
  height: 35px;
}

.bg-pink {
  background-color: #F9BAA9;
}